<template>
  <div class="content member">
    <banner :title="`Team: ${this.member.name || ''}`" :image="5"> </banner>

    <div class="section" v-if="member.name">
      <div class="content_wrapper">

        <div class="member">
          <div
            class="image"
            :style="{
              'background-image': `url(${require(`@/assets/images/team/${this.member.filename}`)})`,
            }"
          ></div>
          <div class="description">
            <h4 class="name">{{ member.name }},</h4>
            <h3 class="title">{{ member.title }}</h3>
            <p>{{ member.description }}</p>
            <button @click="back()" class="default">Back</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import TeamData from "@/team_data.json";

export default {
  name: "Tep-Team-Member",
  components: {
    Banner,
  },
  data() {
    return {
      team: TeamData || [],
    };
  },
  computed: {
    member() {
      return (
        this.team.find((member) => {
          return member.id === this.id;
        }) || {}
      );
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    back() {
      this.$router.push({ path: `/team?scroll=headshot_${this.id}` });
    },
  },
  mounted() {
    window.scrollTo(0, 270);
  },
};
</script>

<style lang="scss" scoped>
.member {
  position: relative;
  min-height: 320px;
  width: 100%;
  padding-left: 0px;
  overflow: hidden;
  // box-shadow: 0 5px 5px -5px gray;
  min-height:53vh;

  .image {
    position: absolute;
    width: 200px;
    height: 300px;
    border: 1px solid var(--font_color_accent);
    top: 36px;
    left: 0;
    background-position: top center;
    background-size: cover;
    background-size: 110%;
    background-repeat: no-repeat;
    box-shadow: 0 5px 10px -5px gray;
    transition: all 0.5s;
    // filter: grayscale(1);
  }
  .description {
    padding-left: 250px;
  }
  .name {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .title {
    margin-top: 0;
    padding-top: 10px;
  }
  &.active {
    .image {
      filter: grayscale(0);
      background-size: 120%;
    }
  }
}

@media screen and (max-width: 700px) {
  .member {
    padding-left: 0;

    .image {
      position: relative;
      float: none;
      display: block;
      margin: 0 auto 50px auto;
    }
    .description {
      padding-left: 0px;
      .name,
      .title {
        text-align: center;
      }
    }
  }
}
</style>
